import { normalizeClass as _normalizeClass, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = ["src"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("img", {
    ref: "image",
    src: _ctx.props.src,
    onLoad: _cache[0] || (_cache[0] = () => (_ctx.isLoading = false)),
    class: _normalizeClass(["transition-all", [{ 'opacity-0': _ctx.isLoading }]])
  }, null, 42, _hoisted_1))
}