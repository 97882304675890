import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, normalizeClass as _normalizeClass, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-cc449734"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "text-[14px] font-bold leading-[136%] text-white" }
const _hoisted_2 = ["src"]
const _hoisted_3 = { class: "flex flex-col gap-1 py-2" }
const _hoisted_4 = ["onClick"]
const _hoisted_5 = { class: "text-left text-[14px] font-normal leading-[136%] text-white" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("div", {
    class: "relative",
    tabindex: "0",
    onFocusout: _cache[1] || (_cache[1] = () => (_ctx.isOpened = false))
  }, [
    _createElementVNode("div", {
      class: "relative flex h-[50px] w-[169px] cursor-pointer items-center justify-between rounded-[8px] border-[1px] border-solid border-[#8390A5] bg-[rgba(245,245,245,0.1)] pl-4 pr-[14px]",
      onClick: _cache[0] || (_cache[0] = () => (_ctx.isOpened = !_ctx.isOpened))
    }, [
      _createElementVNode("div", _hoisted_1, _toDisplayString(_ctx.props.modelValue?.label), 1),
      _createElementVNode("img", {
        src: require('@assets/icons/ArrowDown.svg'),
        class: _normalizeClass(["transition-all duration-300", [{ '-rotate-180': _ctx.isOpened }]])
      }, null, 10, _hoisted_2)
    ]),
    _createElementVNode("div", {
      id: "list",
      class: _normalizeClass(["absolute top-[calc(100%+3px)] left-0 w-full overflow-y-scroll rounded-[8px] border-[1px] border-solid border-[#8390A5] bg-[#333942] transition-all duration-300", [_ctx.isOpened ? 'h-[117px]' : 'h-0 opacity-0']])
    }, [
      _createElementVNode("div", _hoisted_3, [
        (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.props.list, (item, idx) => {
          return (_openBlock(), _createElementBlock("div", {
            key: `item-${idx}`,
            class: _normalizeClass(["py-[6px] px-4 hover:bg-white/10", [{ 'bg-white/10': _ctx.props.modelValue?.value === item.value }]]),
            onClick: ($event: any) => (_ctx.handleSelect(item))
          }, [
            _createElementVNode("span", _hoisted_5, _toDisplayString(item.label), 1)
          ], 10, _hoisted_4))
        }), 128))
      ])
    ], 2)
  ], 32))
}