import { resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createVNode as _createVNode, createElementVNode as _createElementVNode, createElementBlock as _createElementBlock, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, TransitionGroup as _TransitionGroup, withCtx as _withCtx, normalizeClass as _normalizeClass, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-23bc809f"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "flex h-full w-full justify-between py-[30px]" }
const _hoisted_2 = { class: "w-[590px]" }
const _hoisted_3 = { class: "w-[610px]" }
const _hoisted_4 = {
  key: 0,
  class: "absolute top-0 flex h-full w-full items-center justify-center transition-all"
}
const _hoisted_5 = {
  key: 1,
  class: "absolute top-0 flex h-full w-full items-center justify-center transition-all"
}
const _hoisted_6 = ["src"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_UploadVirtual = _resolveComponent("UploadVirtual")!
  const _component_UploadVirtualFace = _resolveComponent("UploadVirtualFace")!
  const _component_SelectAvatar = _resolveComponent("SelectAvatar")!
  const _component_PreviewSection = _resolveComponent("PreviewSection")!
  const _component_DefaultContainer = _resolveComponent("DefaultContainer")!

  return (_openBlock(), _createBlock(_component_DefaultContainer, { class: "h-[calc(100%-80px)] w-full overflow-y-scroll bg-[#1D232D]" }, {
    default: _withCtx(() => [
      _createElementVNode("div", _hoisted_1, [
        _createElementVNode("article", _hoisted_2, [
          (_ctx.$route.path === '/virtual-video')
            ? (_openBlock(), _createBlock(_component_UploadVirtual, {
                key: 0,
                onChange: _ctx.handleFile
              }, null, 8, ["onChange"]))
            : (_openBlock(), _createBlock(_component_UploadVirtualFace, {
                key: 1,
                onChange: _ctx.handleFile
              }, null, 8, ["onChange"])),
          _createVNode(_component_SelectAvatar, { onSelect: _ctx.handleSelect }, null, 8, ["onSelect"])
        ]),
        _createElementVNode("article", _hoisted_3, [
          _createVNode(_component_PreviewSection, {
            src: _ctx.state.previewSrc,
            onInput: _ctx.handleInput
          }, null, 8, ["src", "onInput"]),
          _createElementVNode("button", {
            type: "submit",
            class: _normalizeClass(["relative h-[60px] w-full rounded-[8px] text-[16px] font-bold leading-[136%] tracking-[-0.015em] text-white transition-all", [
            _ctx.audioFormData.text.length > 0 && _ctx.changeFaceFormData.faceToken.length > 0
              ? 'bg-[#4535D8]'
              : 'bg-[rgba(131,144,165,0.6)] pointer-events-none',
            { 'pointer-events-none': _ctx.state.isLoading },
          ]]),
            onClick: _cache[0] || (_cache[0] = 
//@ts-ignore
(...args) => (_ctx.handleGenerate && _ctx.handleGenerate(...args)))
          }, [
            _createVNode(_TransitionGroup, { name: "fade" }, {
              default: _withCtx(() => [
                (!_ctx.state.isLoading)
                  ? (_openBlock(), _createElementBlock("div", _hoisted_4, " 버추얼 비디오 생성하기 "))
                  : (_openBlock(), _createElementBlock("div", _hoisted_5, [
                      _createElementVNode("img", {
                        src: require('@assets/icons/Loading.svg'),
                        class: "mr-1 animate-spin"
                      }, null, 8, _hoisted_6),
                      _createTextVNode(" " + _toDisplayString(_ctx.state.step) + "/4 " + _toDisplayString(_ctx.state.stepLabel) + " " + _toDisplayString(_ctx.state.progress), 1)
                    ]))
              ]),
              _: 1
            })
          ], 2)
        ])
      ])
    ]),
    _: 1
  }))
}