
import { defineComponent, ref } from "vue";

export default defineComponent({
  name: "Video",
  setup() {
    const isLoading = ref(true);

    return { isLoading };
  },
});
