
import { detectFace, getMergeFaceStatus, mergeFace } from "@/api/virtualVideos";
import { defineComponent, onBeforeUnmount, reactive, ref, watchEffect } from "vue";
import { checkStatus } from "../context";
import Slider from "./Slider.vue";
import UploadPicture from "./UploadPicture.vue";

export default defineComponent({
  name: "UploadVirtualFace",
  components: { UploadPicture, Slider },
  emits: ["change"],
  setup(_, { emit }) {
    const isLoading = ref(false);
    const result = ref("");
    const formData = reactive({
      faceAToken: "",
      faceBToken: "",
      mixtureA: 50,
      mixtureB: 50,
    });
    const state = reactive({
      progress: "",
      interval: 0,
    });

    const handleMerge = async () => {
      isLoading.value = true;
      result.value = "";
      emit("change", "");

      try {
        const mergeFaceId = await mergeFace(formData);
        const mergeFaceData = await checkStatus(
          () => getMergeFaceStatus({ id: mergeFaceId.data.id }),
          (e: string) => (state.progress = e),
          (e: number) => (state.interval = e)
        );

        state.progress = "토큰 추출 중...";
        const response = await fetch(mergeFaceData.data.url);
        const data = await response.blob();
        const file = new File([data], "temp_image.jpg");
        const detectFormData = new FormData();
        detectFormData.append("file", file);
        const detectedFace = await detectFace(detectFormData);

        result.value = mergeFaceData.data.url;
        emit("change", detectedFace.data.faceTokens[0]);
      } catch (e) {
        alert(e);
      } finally {
        state.progress = "";
        isLoading.value = false;
      }
    };

    watchEffect(() => {
      if (formData.faceAToken === "" || formData.faceBToken === "") {
        result.value = "";
        emit("change", "");
      }
    });

    onBeforeUnmount(() => {
      clearInterval(state.interval);
    });

    return { isLoading, result, formData, state, handleMerge };
  },
});
