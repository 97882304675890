
import { detectFace } from "@/api/virtualVideos";
import { defineComponent, reactive, ref } from "vue";

export default defineComponent({
  name: "UploadPicture",
  emits: ["change"],
  setup(_, { emit }) {
    const input = ref<HTMLInputElement>();
    const isLoading = ref(false);

    const fileInfo = reactive({
      imageUrl: "",
      name: "",
      type: "",
    });

    const reset = () => {
      if (!input.value?.value) return;
      input.value.value = "";
      fileInfo.imageUrl = "";
      fileInfo.name = "";
      fileInfo.type = "";
      emit("change", "");
    };

    const handleUpload = () => {
      if (fileInfo.imageUrl.length === 0) {
        input.value?.click();
      }
    };

    const onChangeImage = () => {
      if (!input.value?.files) return;

      isLoading.value = true;
      const file = input.value.files[0];
      const formData = new FormData();

      formData.append("file", file);
      detectFace(formData)
        .then(({ data }) => {
          if (data.faceTokens.length === 0) {
            throw new Error("얼굴을 감지하지 못했습니다!");
          }

          fileInfo.imageUrl = URL.createObjectURL(file);
          fileInfo.type = file.type;
          fileInfo.name = file.name;
          emit("change", data.faceTokens[0]);
        })
        .catch((err) => {
          reset();
          alert(err.message);
        })
        .finally(() => {
          isLoading.value = false;
        });
    };

    return { input, isLoading, fileInfo, reset, handleUpload, onChangeImage };
  },
});
