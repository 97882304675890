
import { defineComponent, reactive } from "vue";

import { loginUser } from "@/api/auth";
import { useCookies } from "vue3-cookies";
import { useRouter } from "vue-router";

export default defineComponent({
  name: "SignIn",
  setup() {
    const { cookies } = useCookies();
    const router = useRouter();

    const formData = reactive({
      userId: "",
      password: "",
    });

    const validate = reactive({
      userId: true,
      password: true,
    });

    const handleSubmit = () => {
      loginUser(formData)
        .then(({ data }) => {
          cookies.set("aToken", data.accessToken);
          cookies.set("aUser", data.user.employeeId);
          router.push({ name: "VideoList" });
        })
        .catch(() => {
          validate.userId = false;
          validate.password = false;
        });
    };

    const handleKeyPress = (e: KeyboardEvent) => {
      if (formData.userId.length > 0 && formData.password.length > 0 && e.key === "Enter") {
        handleSubmit();
      }
    };

    return { formData, validate, handleSubmit, handleKeyPress };
  },
});
