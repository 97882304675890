export const languages = [
  { value: "ko-KR", label: "한국어" },
  { value: "en-US", label: "영어" },
  { value: "zh-CN", label: "중국어" },
  { value: "ja-JP", label: "일본어" },
  { value: "es-ES", label: "스페인어" },
];

export const actors = [
  { value: "wonro002-M20H", label: "남자-20대-고음01" },
  { value: "jiho002-M30L", label: "남자-30대-저음01" },
  { value: "seonggon002-M30L", label: "남자-30대-저음02" },
  { value: "kyh002-M50M", label: "남자-50대-중음01" },
  { value: "ysm002-M60M", label: "남자-60대-중음01" },
  { value: "sewon003-F20M", label: "여자-20대-중음01" },
  { value: "jiwon002-F20M", label: "여자-20대-중음02" },
  { value: "hyewon002-F20H", label: "여자-20대-고음01" },
  { value: "jaeeun002-F20H", label: "여자-20대-고음02" },
  { value: "jiah002-F30M", label: "여자-30대-중음01" },
  { value: "sapiens002-F50M", label: "여자-50대-중음01" },
];
