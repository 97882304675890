
import { defineComponent, reactive, onBeforeMount, ref } from "vue";

import { getCloneList } from "@/api/default";
import { CloneBase, CloneMotion } from "@/api/type";

interface Clone {
  id: number;
  name: string;
  motions: CloneMotion[];
}

export default defineComponent({
  name: "SelectAvatar",
  emits: ["select"],
  setup(_props, { emit }) {
    const cloneList = ref<Clone[]>([]);
    const selected = reactive({
      avatar: 0,
      gesture: 0,
    });

    const handleSelectAvatar = (idx: number) => {
      if (selected.avatar !== idx) {
        selected.avatar = idx;
        selected.gesture = 0;
        emit("select", cloneList.value[selected.avatar].motions[selected.gesture]);
      }
    };

    const handleSelectGesture = (idx: number) => {
      if (selected.gesture !== idx) {
        selected.gesture = idx;
        emit("select", cloneList.value[selected.avatar].motions[selected.gesture]);
      }
    };

    onBeforeMount(() => {
      getCloneList().then(({ data }) => {
        cloneList.value = data.bases.map((base: CloneBase) => ({
          ...base,
          motions: data.motions.filter(
            (motion: CloneMotion) => motion.cloneId === base.id && motion.id >= 21
          ),
        }));
        emit("select", cloneList.value[selected.avatar].motions[selected.gesture]);
      });
    });

    return { cloneList, selected, handleSelectAvatar, handleSelectGesture };
  },
});
