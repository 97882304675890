import { AxiosResponse } from "axios";

export const checkStatus = (
  apiFn: () => Promise<AxiosResponse>,
  onProgress: (progress: string) => void,
  onInterval: (interval: number) => void
): Promise<AxiosResponse> => {
  return new Promise((resolve, reject) => {
    const interval = setInterval(() => {
      apiFn()
        .then((res) => {
          switch (res.data.status) {
            case 0:
              onProgress("(대기열)");
              break;
            case 1:
              onProgress(`${res.data.progress}%`);
              break;
            case 2:
              onProgress(`${res.data.progress}%`);
              clearInterval(interval);
              resolve(res);
              break;
            default:
              onProgress("(Unknown)");
              break;
          }
        })
        .catch((e) => {
          clearInterval(interval);
          reject(e);
        });
    }, 3000);

    onInterval(interval);
  });
};
