
import { defineComponent, watch, ref } from "vue";

export default defineComponent({
  name: "Image",
  props: {
    src: {
      type: String,
      required: false,
      default: "",
    },
  },
  setup(props) {
    const image = ref<HTMLImageElement>();
    const isLoading = ref(true);

    watch(
      () => props.src,
      () => {
        isLoading.value = true;
      }
    );

    return { props, image, isLoading };
  },
});
