import { AxiosResponse } from "axios";

import api from "@/api";
import {
  VideoListParams,
  FaceRequest,
  AudioRequest,
  LipsRequest,
  ResultRequest,
  FaceChangeRequest,
} from "./type";

export const getVideoList = (params: VideoListParams): Promise<AxiosResponse> =>
  api.get("/virtual-videos", { params });

export const detectFace = (request: FormData): Promise<AxiosResponse> =>
  api.post("/virtual-videos/image/detect", request);

export const mergeFace = (request: FaceRequest): Promise<AxiosResponse> =>
  api.post("/virtual-videos/face", request);

export const getMergeFaceStatus = (params: { id: string }): Promise<AxiosResponse> =>
  api.get("/virtual-videos/face", { params });

export const changeFace = (request: FaceChangeRequest): Promise<AxiosResponse> =>
  api.post("/virtual-videos/video/face-change", request);

export const getChangeFaceStatus = (params: { id: string }): Promise<AxiosResponse> =>
  api.get("/virtual-videos/video/face-change", { params });

export const getFaceToken = (request: { token: string }): Promise<AxiosResponse> =>
  api.post("/virtual-videos/video/faces", request);

export const generateAudio = (request: AudioRequest): Promise<AxiosResponse> =>
  api.post("/virtual-videos/audio", request);

export const generateLips = (request: LipsRequest): Promise<AxiosResponse> =>
  api.post("/virtual-videos/lips", request);

export const getLipsStatus = (params: { id: string }): Promise<AxiosResponse> =>
  api.get("/virtual-videos/lips", { params });

export const setResult = (request: ResultRequest): Promise<AxiosResponse> =>
  api.post("/virtual-videos/result", request);
