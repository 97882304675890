
import { defineComponent, ref } from "vue";
import { useCookies } from "vue3-cookies";
import DefaultContainer from "./DefaultContainer.vue";

const menus = [
  { label: "버추얼 비디오 생성", to: "VirtualVideo" },
  { label: "가상 얼굴 비디오 생성", to: "VirtualFaceVideo" },
  { label: "비디오 리스트", to: "VideoList" },
];

export default defineComponent({
  name: "Header",
  components: {
    DefaultContainer,
  },
  setup() {
    const menuOpen = ref(false);
    const { cookies } = useCookies();

    const handleLogout = () => {
      cookies.remove("aToken");
      cookies.remove("aUser");
      location.reload();
    };

    return { menus, menuOpen, cookies, handleLogout };
  },
});
