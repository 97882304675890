
import { defineComponent } from "vue";
import DefaultContainer from "./DefaultContainer.vue";

export default defineComponent({
  name: "Footer",
  components: {
    DefaultContainer,
  },
});
