
import { defineComponent, reactive, onBeforeUnmount } from "vue";
import DefaultContainer from "./components/DefaultContainer.vue";
import UploadVirtual from "./components/UploadVirtual.vue";
import UploadVirtualFace from "./components/UploadVirtualFace.vue";
import PreviewSection from "./components/PreviewSection.vue";
import SelectAvatar from "./components/SelectAvatar.vue";
import { Option } from "./components/type";
import { useRouter } from "vue-router";
import { CloneMotion } from "@/api/type";
import {
  generateAudio,
  generateLips,
  getLipsStatus,
  setResult,
  changeFace,
  getChangeFaceStatus,
  getFaceToken,
} from "@/api/virtualVideos";
import { checkStatus } from "./context";

export default defineComponent({
  name: "VirtualVideo",
  components: {
    DefaultContainer,
    UploadVirtual,
    PreviewSection,
    SelectAvatar,
    UploadVirtualFace,
  },
  setup() {
    const audioFormData = reactive({
      text: "",
      actor: "",
      lang: "",
    });
    const changeFaceFormData = reactive({
      cloneMotionId: 1,
      faceToken: "",
    });
    const lipsFormData = reactive({
      videoToken: "",
      faceToken: "",
      audioToken: "",
      cloneMotionId: 1,
    });
    const resultFormData = reactive({
      cloneId: 1,
      videoToken: "",
      text: "",
      voiceName: "",
    });
    const state = reactive({
      isLoading: false,
      step: 0,
      stepLabel: "",
      progress: "",
      interval: 0,
      previewSrc: "",
    });

    const router = useRouter();

    const handleSelect = (e: CloneMotion) => {
      changeFaceFormData.cloneMotionId = e.id;
      lipsFormData.cloneMotionId = e.id;
      state.previewSrc = `https://klleon-media.s3.ap-northeast-2.amazonaws.com/media/public/deephuman__aiv_admin/video/720p24/${e.videoToken}.webm`;
      resultFormData.cloneId = e.cloneId;
    };

    const handleInput = (e: { text: string; language: Option; actor: Option }) => {
      audioFormData.text = e.text;
      audioFormData.lang = e.language.value;
      audioFormData.actor = e.actor.value;
      resultFormData.text = e.text;
      resultFormData.voiceName = e.actor.value;
    };

    const handleFile = (e: string) => {
      changeFaceFormData.faceToken = e;
    };

    const handleGenerate = async () => {
      state.isLoading = true;

      try {
        state.step = 1;
        state.stepLabel = "오디오 생성 중...";
        const audioData = await generateAudio(audioFormData);
        lipsFormData.audioToken = audioData.data.audioToken;

        state.step = 2;
        state.stepLabel = "얼굴 변환 중...";
        state.progress = "(대기열)";
        const changeFaceId = await changeFace(changeFaceFormData);
        const changeFaceData = await checkStatus(
          () => getChangeFaceStatus({ id: changeFaceId.data.id }),
          (e: string) => (state.progress = e),
          (e: number) => (state.interval = e)
        );
        lipsFormData.videoToken = changeFaceData.data.token;

        state.step = 3;
        state.stepLabel = "토큰 조회 중...";
        state.progress = "";
        const changedFaceToken = await getFaceToken({
          token: changeFaceData.data.token,
        });
        lipsFormData.faceToken = changedFaceToken.data.faceTokens[0];

        state.step = 4;
        state.stepLabel = "입모양 변환 중...";
        state.progress = "0%";
        const lipsId = await generateLips(lipsFormData);
        const lipsData = await checkStatus(
          () => getLipsStatus({ id: lipsId.data.id }),
          (e: string) => (state.progress = e),
          (e: number) => (state.interval = e)
        );
        resultFormData.videoToken = lipsData.data.token;

        state.stepLabel = "등록 중...";
        state.progress = "";
        await setResult(resultFormData);
        router.push("/");
      } catch (e) {
        alert(e);
      } finally {
        state.step = 0;
        state.progress = "";
        state.stepLabel = "";
        state.isLoading = false;
      }
    };

    onBeforeUnmount(() => {
      clearInterval(state.interval);
    });

    return {
      audioFormData,
      changeFaceFormData,
      lipsFormData,
      state,
      handleSelect,
      handleInput,
      handleFile,
      handleGenerate,
    };
  },
});
