
import { VideoInfo } from "@/api/type";
import { getVideoList } from "@/api/virtualVideos";
import { convertNumberToTimestamp } from "@/utils/formatter";
import { defineComponent, onBeforeMount, reactive, ref } from "vue";
import { useRoute, useRouter } from "vue-router";
import Image from "../components/Image.vue";
import DefaultContainer from "./components/DefaultContainer.vue";
import VideoModal from "./components/VideoModal.vue";

export default defineComponent({
  name: "VideoList",
  components: {
    DefaultContainer,
    VideoModal,
    Image,
  },
  setup() {
    const searchValue = ref("");
    const videoList = ref<VideoInfo[]>([]);
    const modalState = reactive({
      isModal: false,
      videoInfo: videoList.value[0],
    });

    const router = useRouter();
    const route = useRoute();

    const handleSearch = () => {
      router.push({ query: { search: searchValue.value } });
      getVideoList({ page: 1, limit: 50, name: searchValue.value })
        .then(({ data }) => {
          videoList.value = data;
        })
        .catch((err) => {
          alert(err);
        });
    };

    const handleKeyPress = (e: KeyboardEvent) => {
      if (e.key === "Enter") {
        handleSearch();
      }
    };

    const handleOpen = (video: VideoInfo) => {
      modalState.videoInfo = video;
      modalState.isModal = true;
    };

    onBeforeMount(() => {
      if (route.query.search) {
        searchValue.value = route.query.search as string;
      }
      handleSearch();
    });

    return {
      convertNumberToTimestamp,
      searchValue,
      videoList,
      modalState,
      handleKeyPress,
      handleSearch,
      handleOpen,
    };
  },
});
