import { vModelText as _vModelText, createElementVNode as _createElementVNode, withDirectives as _withDirectives, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, resolveComponent as _resolveComponent, createVNode as _createVNode, toDisplayString as _toDisplayString, TransitionGroup as _TransitionGroup, withCtx as _withCtx, createBlock as _createBlock, createCommentVNode as _createCommentVNode, Transition as _Transition, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-ce3b9a8a"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "h-full w-full pt-5 pb-12" }
const _hoisted_2 = { class: "relative mb-5 h-12" }
const _hoisted_3 = ["src"]
const _hoisted_4 = ["onClick"]
const _hoisted_5 = { class: "mb-4 h-[282px] w-full overflow-hidden rounded-[20px] bg-[#D9D9D9]" }
const _hoisted_6 = { class: "mb-[2px] text-left text-[16px] font-bold leading-[160%] tracking-[-0.015em]" }
const _hoisted_7 = { class: "text-left text-[14px] font-normal leading-[160%] text-[#ADADAD]" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_Image = _resolveComponent("Image")!
  const _component_VideoModal = _resolveComponent("VideoModal")!
  const _component_DefaultContainer = _resolveComponent("DefaultContainer")!

  return (_openBlock(), _createBlock(_component_DefaultContainer, { class: "min-h-[calc(100%-160px)] overflow-y-scroll bg-[#FDFDFD]" }, {
    default: _withCtx(() => [
      _createElementVNode("div", _hoisted_1, [
        _createElementVNode("div", _hoisted_2, [
          _withDirectives(_createElementVNode("input", {
            "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.searchValue) = $event)),
            type: "text",
            placeholder: "원하는 버추얼 휴먼을 검색하세요.",
            class: "relative h-full w-full rounded-2xl border-[1px] border-solid border-[rgba(84,139,246,0.5)] bg-[rgba(84,139,246,0.04)] px-5 text-[14px] font-normal leading-[160%] tracking-[-0.015em]",
            onKeypress: _cache[1] || (_cache[1] = 
//@ts-ignore
(...args) => (_ctx.handleKeyPress && _ctx.handleKeyPress(...args)))
          }, null, 544), [
            [_vModelText, _ctx.searchValue]
          ]),
          _createElementVNode("img", {
            src: require('@assets/icons/Search.svg'),
            class: "absolute top-[50%] right-5 h-[30px] translate-y-[-50%] cursor-pointer",
            onClick: _cache[2] || (_cache[2] = 
//@ts-ignore
(...args) => (_ctx.handleSearch && _ctx.handleSearch(...args)))
          }, null, 8, _hoisted_3)
        ]),
        _createVNode(_TransitionGroup, {
          appear: "",
          tag: "div",
          name: "list",
          class: "grid grid-cols-5 gap-x-5 gap-y-[26px]"
        }, {
          default: _withCtx(() => [
            (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.videoList, (video, idx) => {
              return (_openBlock(), _createElementBlock("div", {
                key: `video-${idx}`,
                class: "w-full cursor-pointer",
                onClick: ($event: any) => (_ctx.handleOpen(video))
              }, [
                _createElementVNode("div", _hoisted_5, [
                  _createVNode(_component_Image, {
                    src: video.thumbnailUrl,
                    alt: video.thumbnailUrl,
                    class: "h-full w-full object-cover"
                  }, null, 8, ["src", "alt"])
                ]),
                _createElementVNode("div", _hoisted_6, _toDisplayString(video.title), 1),
                _createElementVNode("div", _hoisted_7, _toDisplayString(_ctx.convertNumberToTimestamp(video.created)), 1)
              ], 8, _hoisted_4))
            }), 128))
          ]),
          _: 1
        }),
        _createVNode(_Transition, { name: "fade" }, {
          default: _withCtx(() => [
            (_ctx.modalState.isModal)
              ? (_openBlock(), _createBlock(_component_VideoModal, {
                  key: 0,
                  value: _ctx.modalState.videoInfo,
                  modelValue: _ctx.modalState.isModal,
                  "onUpdate:modelValue": _cache[3] || (_cache[3] = ($event: any) => ((_ctx.modalState.isModal) = $event))
                }, null, 8, ["value", "modelValue"]))
              : _createCommentVNode("", true)
          ]),
          _: 1
        })
      ])
    ]),
    _: 1
  }))
}