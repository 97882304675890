
import { generateAudio } from "@/api/virtualVideos";
import { defineComponent, reactive, ref } from "vue";
import Video from "../../components/Video.vue";
import DropdownInput from "./DropdownInput.vue";
import { actors, languages } from "./static";

export default defineComponent({
  name: "PreviewSection",
  components: { DropdownInput, Video },
  props: {
    src: {
      type: String,
    },
  },
  emits: ["input"],
  setup(props, { emit }) {
    const isLoading = ref(false);
    const isPlaying = ref(false);
    const textarea = ref<HTMLTextAreaElement>();
    const audio = ref<HTMLAudioElement>();

    const inputData = reactive({
      text: "",
      language: languages[0],
      actor: actors[0],
    });
    const cache = reactive({
      text: "",
      language: "",
      actor: "",
    });

    const handleInput = () => {
      inputData.text = textarea?.value?.value as string;
      emit("input", inputData);
    };

    const handleListen = () => {
      if (!audio.value) return;

      if (isPlaying.value) {
        audio.value.pause();
        isPlaying.value = false;
        return;
      }

      if (
        !isPlaying.value &&
        inputData.text === cache.text &&
        inputData.language.value === cache.language &&
        inputData.actor.value === cache.actor
      ) {
        audio.value.play();
        isPlaying.value = true;
        return;
      }

      if (inputData.text.length > 0) {
        isLoading.value = true;

        generateAudio({
          text: inputData.text,
          lang: inputData.language.value,
          actor: inputData.actor.value,
        })
          .then(({ data }) => {
            if (!audio.value) return;
            audio.value.src = data.url;
            audio.value.play();
            cache.text = inputData.text;
            cache.language = inputData.language.value;
            cache.actor = inputData.actor.value;
            isPlaying.value = true;
          })
          .catch((err) => {
            alert(err.message);
          })
          .finally(() => {
            isLoading.value = false;
          });
      }
    };

    return {
      languages,
      actors,
      props,
      isPlaying,
      isLoading,
      textarea,
      audio,
      inputData,
      handleInput,
      handleListen,
    };
  },
});
