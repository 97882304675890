import { createRouter, createWebHistory, RouteRecordRaw } from "vue-router";
import SignIn from "../views/SignIn.vue";
import { useCookies } from "vue3-cookies";

import VideoList from "@/views/VideoList.vue";
import VirtualVideo from "@/views/VirtualVideo.vue";

const { cookies } = useCookies();

const routes: Array<RouteRecordRaw> = [
  {
    path: "/",
    redirect: "/video-list",
  },
  {
    path: "/sign-in",
    name: "SignIn",
    component: SignIn,
  },
  {
    path: "/video-list",
    name: "VideoList",
    component: VideoList,
  },
  {
    path: "/virtual-face-video",
    name: "VirtualFaceVideo",
    component: VirtualVideo,
  },
  {
    path: "/virtual-video",
    name: "VirtualVideo",
    component: VirtualVideo,
  },
];

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
});

router.beforeEach((to, _from, next) => {
  const value = cookies.get("aToken");
  if (to.name === "SignIn") {
    next();
  } else if (!value) {
    next("/sign-in");
  } else {
    next();
  }
});

export default router;
