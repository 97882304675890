
import { computed, defineComponent, ref } from "vue";
import { useRoute } from "vue-router";
import Footer from "./views/components/Footer.vue";
import Header from "./views/components/Header.vue";

export default defineComponent({
  components: {
    Header,
    Footer,
  },
  setup() {
    const headerRef = ref();
    const route = useRoute();

    const bg = computed(() => {
      if (route.name === "Studio") {
        return "studio";
      }
      return "default";
    });
    return {
      headerRef,
      bg,
    };
  },
});
