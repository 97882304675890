import { renderSlot as _renderSlot, normalizeClass as _normalizeClass, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("video", {
    class: _normalizeClass(["transition-all", [{ 'opacity-0': _ctx.isLoading }]]),
    onEmptied: _cache[0] || (_cache[0] = () => (_ctx.isLoading = true)),
    onCanplay: _cache[1] || (_cache[1] = () => (_ctx.isLoading = false))
  }, [
    _renderSlot(_ctx.$slots, "default")
  ], 34))
}