import { AxiosResponse } from "axios";

import api from "@/api";

import { SignInUser } from "./type";

export const loginUser = async (userData: SignInUser): Promise<AxiosResponse> => {
  const response = await api.post("/login", userData);
  return response;
};
