import axios from "axios";
import { useCookies } from "vue3-cookies";

const instance = axios.create({
  baseURL: `https://api.deephuman.klleon.io/aiv`,
});

instance.interceptors.request.use(
  (config) => {
    const { cookies } = useCookies();
    const aToken = cookies.get("aToken");
    const tempConfig = config;

    if (aToken && tempConfig.headers) {
      tempConfig.headers.Authorization = `Bearer ${cookies.get("aToken")}`;
    }

    return config;
  },
  (error) => Promise.reject(error)
);

export default instance;
