
import { defineComponent, PropType, ref } from "vue";
import DefaultContainer from "./DefaultContainer.vue";
import { VideoInfo } from "@/api/type";
import { convertNumberToTimestamp } from "@/utils/formatter";
import Video from "../../components/Video.vue";

export default defineComponent({
  name: "VideoModal",
  components: {
    DefaultContainer,
    Video,
  },
  props: {
    value: {
      type: Object as PropType<VideoInfo>,
    },
    modelValue: {
      type: Boolean,
    },
  },
  emits: ["update:modelValue"],
  setup(props) {
    const isPlayed = ref(false);
    const canPlay = ref(false);
    const video = ref();

    const handlePlay = () => {
      if (canPlay.value) {
        isPlayed.value = true;
        video.value.$el.play();
      }
    };

    return {
      convertNumberToTimestamp,
      props,
      isPlayed,
      canPlay,
      video,
      handlePlay,
    };
  },
});
