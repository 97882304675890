
import { defineComponent, ref, onMounted, onUnmounted } from "vue";

export default defineComponent({
  name: "Slider",
  props: {
    modelValue: {
      type: Number,
      default: 50,
    },
  },
  emits: ["update:modelValue"],
  setup(props, { emit }) {
    const bar = ref<HTMLDivElement>();
    const isMouseDown = ref(false);

    const slideFactory = {
      _getOffsetLeft: (e: HTMLDivElement): number => {
        if (!e.offsetParent) return e.offsetLeft;
        return e.offsetLeft + slideFactory._getOffsetLeft(e.offsetParent as HTMLDivElement);
      },
      move: (clientX: number) => {
        if (!bar.value || !isMouseDown.value) return;
        const left = slideFactory._getOffsetLeft(bar.value);
        const width = bar.value.offsetWidth;
        let percent = Math.floor(((clientX - left + 1) / width) * 100);

        if (percent < 0) percent = 0;
        if (percent > 100) percent = 100;
        emit("update:modelValue", percent);
      },
      startMove: (clientX: number) => {
        isMouseDown.value = true;
        slideFactory.move(clientX);
      },
      stopMove: () => {
        isMouseDown.value = false;
      },
    };

    onMounted(() => {
      document.addEventListener("mousemove", (e) => slideFactory.move(e.clientX));
      document.addEventListener("touchmove", (e) => slideFactory.move(e.touches[0].clientX));
      document.addEventListener("mouseup", slideFactory.stopMove);
      document.addEventListener("touchend", slideFactory.stopMove);
    });
    onUnmounted(() => {
      document.removeEventListener("mousemove", (e) => slideFactory.move(e.clientX));
      document.removeEventListener("touchmove", (e) => slideFactory.move(e.touches[0].clientX));
      document.removeEventListener("mouseup", slideFactory.stopMove);
      document.removeEventListener("touchend", slideFactory.stopMove);
    });

    return {
      props,
      bar,
      isMouseDown,
      slideFactory,
    };
  },
});
