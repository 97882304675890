
import { defineComponent, PropType, ref } from "vue";
import { Option } from "./type";

export default defineComponent({
  name: "DropdownInput",
  props: {
    list: {
      type: Array as PropType<Option[]>,
    },
    modelValue: {
      type: Object as PropType<Option>,
    },
  },
  emits: ["update:modelValue"],
  setup(props, { emit }) {
    const isOpened = ref(false);

    const handleSelect = (item: Option) => {
      emit("update:modelValue", item);
      isOpened.value = false;
    };

    return { props, isOpened, handleSelect };
  },
});
